import {
  colorBlueDark,
  colorBlueLightest,
  colorBlueMedium,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ForwardRefRenderFunction } from "react";
import { forwardRef } from "react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import type { IconSource } from "../Icon/types";
import Text from "../Text";

const menuItemStyle = css`
  background-color: ${colorWhite};
  color: ${colorBlueMedium};
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${colorBlueLightest};
    color: ${colorBlueDark};
    outline: none;
  }
`;

const menuItemWithDescriptionStyle = css`
  padding: 1rem 2rem;
`;

const menuItemWithoutDescriptionStyle = css`
  padding: 0.75rem 1.5rem;
`;

export interface MenuListItemProps {
  className?: string;
  compact?: boolean;
  description?: string;
  handleClose: () => void;
  icon?: IconSource;
  link: string;
  onHover?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLAnchorElement>;
  onMouseLeave?: () => void;
  title: string;
  titleIcon?: IconSource;
}

const MenuListItem: ForwardRefRenderFunction<
  HTMLAnchorElement,
  MenuListItemProps
> = (
  {
    className,
    compact = false,
    icon,
    link,
    handleClose,
    title,
    titleIcon,
    description,
    onHover,
    onKeyDown,
    onMouseLeave,
  },
  ref,
) => {
  const onLinkClick = () => {
    // Delaying the link click so navigation (if applicable) can occur first
    const NAV_CLOSE_DELAY = 100;
    setTimeout(handleClose, NAV_CLOSE_DELAY);
  };

  return (
    <Anchor
      className={className}
      color={"inherit"}
      css={[
        menuItemStyle,
        compact
          ? menuItemWithoutDescriptionStyle
          : menuItemWithDescriptionStyle,
      ]}
      href={link}
      onClick={onLinkClick}
      onFocus={onHover}
      onKeyDown={onKeyDown}
      onMouseLeave={onMouseLeave}
      onMouseOver={onHover}
      ref={ref}
      role={"listitem"}
    >
      <div
        css={css`
          display: flex;
          gap: 1rem;
        `}
      >
        {icon ? (
          <Icon
            color={"transparent"}
            css={css`
              flex: 0 0 32px;
            `}
            size={"32px"}
            source={icon}
            yPos={"6px"}
          />
        ) : null}
        <div>
          <div
            css={css`
              align-items: center;
              display: flex;
              gap: 1rem;
            `}
          >
            <Text
              as={"span"}
              color={"inherit"}
              css={css`
                line-height: 1.5;
              `}
              size={"small"}
              weight={"semibold"}
            >
              {title}
            </Text>
            {titleIcon ? (
              <Icon color={"inherit"} size={"16px"} source={titleIcon} />
            ) : null}
          </div>
          {description && (
            <Text
              as={"p"}
              color={"steelDarker"}
              css={css`
                line-height: 1.5;
              `}
              size={"small"}
              weight={"regular"}
            >
              {description}
            </Text>
          )}
        </div>
      </div>
    </Anchor>
  );
};

export default forwardRef(MenuListItem);
