import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { colorSteelDarkest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Icon from "../Icon";
import Text from "../Text";

interface Props {
  items: NonNullable<Navigation_FullFragment["menu"]>;
  onClick: (item: NonNullable<Navigation_FullFragment["menu"]>[0]) => void;
}

const MobileNavItemsCss = css`
  margin: 0;
  padding: 0;
  display: grid;
`;

const MobileNavItemCss = css`
  list-style: none;
  width: 100%;

  > button {
    align-items: center;
    background: none;
    border: none;
    color: ${colorSteelDarkest};
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    text-align: left;
    width: 100%;
  }
`;

const MobileNavItems: FunctionComponent<Props> = ({ items, onClick }) => {
  const search = { items: null, label: "Search", type: "link", url: "/search" };
  const pricing = {
    items: null,
    label: "Store",
    type: "link",
    url: "/store",
  };
  return (
    <ul css={MobileNavItemsCss}>
      {items.map((item) => {
        const { label } = item;
        return (
          <li css={MobileNavItemCss} key={label}>
            <button onClick={() => onClick(item)}>
              <Text
                as={"span"}
                color={"steelDarker"}
                css={css`
                  margin-right: 0.5rem;
                `}
                size={"medium"}
                weight={"semibold"}
              >
                {label}
              </Text>
              <Icon
                color={"base"}
                size={"10px"}
                source={"nav-right"}
                yPos={"2px"}
              />
            </button>
          </li>
        );
      })}
      <li css={MobileNavItemCss}>
        <button onClick={() => onClick(pricing)}>
          <Text
            as={"span"}
            color={"steelDarker"}
            css={css`
              margin-right: 0.5rem;
            `}
            size={"medium"}
            weight={"semibold"}
          >
            {pricing.label}
          </Text>
          <Icon
            color={"base"}
            size={"10px"}
            source={"nav-right"}
            yPos={"2px"}
          />
        </button>
      </li>
      <li css={MobileNavItemCss}>
        <button onClick={() => onClick(search)}>
          <Text
            as={"span"}
            color={"steelDarker"}
            css={css`
              margin-right: 0.5rem;
            `}
            size={"medium"}
            weight={"semibold"}
          >
            {search.label}
          </Text>
          <Icon
            color={"base"}
            size={"10px"}
            source={"nav-right"}
            yPos={"2px"}
          />
        </button>
      </li>
    </ul>
  );
};

export default MobileNavItems;
