import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Text from "../Text";

type Direction = "column" | "row";

interface Props {
  direction: Direction;
  items: NonNullable<NonNullable<Navigation_FullFragment["menu"]>[0]["items"]>;
  onNavigate: () => void;
}

const NavSections: FunctionComponent<Props> = ({
  direction,
  items,
  onNavigate,
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${direction};
        gap: 20px;
      `}
    >
      {items.map((item) => {
        return (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              padding: 0.75rem 0;
            `}
            key={item.id}
          >
            <Anchor
              color={"inherit"}
              href={item.link ?? ""}
              onClick={onNavigate}
              target={"_self"}
              underlineOnHover={true}
            >
              <Text
                as={"span"}
                color={"blueMedium"}
                size={"small"}
                weight={"semibold"}
              >
                {item.title}
              </Text>
            </Anchor>
            {item.description ? (
              <Text
                as={"span"}
                color={"steelDarker"}
                size={"small"}
                weight={"regular"}
              >
                {item.description}
              </Text>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default NavSections;
