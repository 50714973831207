import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { colorSteelDarkest, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useRef, useState } from "react";
import Popup from "reactjs-popup";
import type { PopupActions } from "reactjs-popup/dist/types";

import Anchor from "../Anchor";
import Button from "../Button";
import Icon from "../Icon";
import Text from "../Text";
import type { CompanyMenuItem } from "./CompanyMenu";
import CompanyMenu from "./CompanyMenu";
import NavigationLink from "./DesktopNavLink";
import type { ProductMenuItem } from "./ProductMenu";
import ProductMenu from "./ProductMenu";
import type { ResourceMenuItem } from "./ResourceMenu";
import ResourceMenu from "./ResourceMenu";

interface Props {
  data: Navigation_FullFragment;

  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: "light" | "dark";
}

export type NavItem =
  | ProductNavMenu
  | ResourceNavMenu
  | CompanyMenu
  | LinkNavMenu;

interface BaseNavItem {
  label: string;
  type: string;
}

interface ProductNavMenu extends BaseNavItem {
  items: ProductMenuItem[];
  type: "productMenu";
}

interface ResourceNavMenu extends BaseNavItem {
  items: ResourceMenuItem[];
  type: "resourceMenu";
}

interface CompanyMenu extends BaseNavItem {
  items: CompanyMenuItem[];
  type: "companyMenu";
}

interface LinkNavMenu extends BaseNavItem {
  type: "link";
  url: string;
}

const MENU_TARGET_ID = "navMenu";

const DesktopNav: FunctionComponent<Props> = ({ data, mode }) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const menuRefs = useRef<(HTMLElement | null)[]>([]);
  const popupRefs = useRef<(PopupActions | null)[]>([]);
  const search = { label: "Search", sections: null, url: "/search" };
  const pricing = {
    label: "Store",
    sections: null,
    url: "/store",
  };
  const color = mode === "dark" ? colorWhite : colorSteelDarkest;
  const textColor = mode === "dark" ? "white" : "base";
  const lastFocusedElement = useRef<HTMLElement | null>(null);
  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    lastFocusedElement.current = event.target as HTMLElement;
  };

  const navMenuStyle = css`
    box-sizing: border-box;
    z-index: 100;
    @media (max-width: 1070px) {
      display: none;
    }
    width: 100%;
  `;

  const navListStyle = css`
    box-sizing: border-box;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 40px;
    height: 3rem;
  `;

  const activeNavItemStyle = css`
    outline: none;
    &:after {
      transform: scaleX(1);
    }
  `;

  const navItemStyle = css`
    box-sizing: border-box;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0;
    position: relative;
    outline: none;
    &:focus-visible {
      outline: none;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: ${color};
      transform: scaleX(0);
      transition: transform 0.1s ease;
    }
    &:hover:after,
    &:focus:after {
      transform: scaleX(1);
    }
  `;

  const getNavCloseHandler = (index: number) => () => {
    popupRefs.current[index]?.close();
    menuRefs.current[index]?.focus();
  };

  const getNavTabForwardHandler = (index: number) => () => {
    popupRefs.current[index]?.close();
    menuRefs.current[Math.min(menuRefs.current.length - 1, index + 1)]?.focus();
  };

  const getNavTabBackwardHandler = (index: number) => () => {
    popupRefs.current[index]?.close();
    menuRefs.current[Math.max(0, index - 1)]?.focus();
  };

  const renderNavPopup = (
    navItem: ProductNavMenu | ResourceNavMenu | CompanyMenu,
    index: number,
  ) => {
    const commonProps = {
      handleClose: getNavCloseHandler(index),
      onEscapeKeyDown: getNavCloseHandler(index),
      onShiftTabKeyDown: getNavTabBackwardHandler(index),
      onTabKeyDown: getNavTabForwardHandler(index),
    };

    return (
      <Popup
        arrow={false}
        closeOnDocumentClick
        key={navItem.label}
        offsetX={navItem.type === "companyMenu" ? -48 : -120}
        offsetY={16}
        on={["hover", "click"]}
        onClose={() => setActiveIndex(-1)}
        onOpen={() => setActiveIndex(index)}
        position={"bottom left"}
        ref={(el) => (popupRefs.current[index] = el)}
        trigger={
          <li key={navItem.label}>
            <Button
              aria-expanded={activeIndex === index}
              aria-haspopup={true}
              background={"transparent"}
              css={[navItemStyle, activeIndex === index && activeNavItemStyle]}
              onBlur={handleBlur}
              onFocus={() => popupRefs.current[index]?.open()}
              ref={(el) => (menuRefs.current[index] = el)}
            >
              <Text
                as={"span"}
                color={textColor}
                size={"small"}
                weight={"semibold"}
              >
                {navItem.label}
              </Text>
            </Button>
          </li>
        }
      >
        {navItem.type === "productMenu" && (
          <ProductMenu menuItems={navItem.items} {...commonProps} />
        )}
        {navItem.type === "resourceMenu" && (
          <ResourceMenu menuItems={navItem.items} {...commonProps} />
        )}
        {navItem.type === "companyMenu" && (
          <CompanyMenu menuItems={navItem.items} {...commonProps} />
        )}
      </Popup>
    );
  };

  const renderNavLink = (navItem: LinkNavMenu, index: number) => (
    <li key={navItem.url}>
      <Anchor
        css={[navItemStyle, activeIndex === index && activeNavItemStyle]}
        href={navItem.url}
        ref={(el) => (menuRefs.current[index] = el)}
      >
        <Text as={"span"} color={textColor} size={"small"} weight={"semibold"}>
          {navItem.label}
        </Text>
      </Anchor>
    </li>
  );

  return (
    <nav aria-label={"site navigation"} css={navMenuStyle}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <ul css={navListStyle}>
          {data?.menu?.map((navItem, index) =>
            navItem.type === "link"
              ? renderNavLink(navItem as LinkNavMenu, index)
              : renderNavPopup(
                  navItem as ProductNavMenu | ResourceNavMenu | CompanyMenu,
                  index,
                ),
          )}
        </ul>

        <ul
          css={[
            navListStyle,
            css`
              align-items: center;
              gap: 24px;
            `,
          ]}
        >
          <NavigationLink
            id={"pricing-main-nav"}
            mode={mode}
            ref={(el) => (menuRefs.current[data?.menu?.length ?? 0] = el)}
            target={"_blank"}
            url={pricing.url}
          >
            <Text
              as={"span"}
              color={"inherit"}
              size={"xsmall"}
              weight={"semibold"}
            >
              {pricing.label}
            </Text>
          </NavigationLink>

          <NavigationLink
            mode={mode}
            ref={(el) => (menuRefs.current[(data?.menu?.length ?? 0) + 1] = el)}
            url={search.url}
          >
            <Icon
              color={"inherit"}
              css={css`
                margin-right: 6px;
              `}
              size={"12px"}
              source={"search"}
              yPos={"1px"}
            />
            <Text
              as={"span"}
              color={"inherit"}
              size={"xsmall"}
              weight={"semibold"}
            >
              {search.label}
            </Text>
          </NavigationLink>
        </ul>
      </div>
      <div id={MENU_TARGET_ID} />
    </nav>
  );
};

export default DesktopNav;
