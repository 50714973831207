import { borderRadiusMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { forwardRef, type ForwardRefRenderFunction } from "react";

import Anchor from "../Anchor";

interface Props {
  children: ReactNode;
  className?: string;
  /** Specifies white text (dark mode) or blue text (light mode). */
  id?: string;
  mode?: "light" | "dark";
  target?: "_blank" | "_self";
  url: string;
}

const NavigationLink: ForwardRefRenderFunction<HTMLAnchorElement, Props> = (
  { children, className, id, mode, target = "_self", url },
  ref,
) => {
  const textColor = mode === "dark" ? "white" : "base";
  return (
    <li
      className={className}
      css={css`
        display: inline-block;
      `}
      id={id}
    >
      <Anchor
        css={css`
          border-radius: ${borderRadiusMedium};
          color: ${textColor};
          padding: 4px 10px 6px;
          display: block;
          background: ${mode === "dark"
            ? "rgba(225, 239, 252, 0.25)"
            : "rgba(68, 89, 121, 0.1)"};
        `}
        hoverColor={textColor}
        href={url}
        ref={ref}
        target={target}
      >
        {children}
      </Anchor>
    </li>
  );
};

export default forwardRef(NavigationLink);
