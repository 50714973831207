import { colorSteelLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef, type ForwardRefRenderFunction } from "react";

import MenuListItem from "./MenuListItem";
import type { SubmenuKeydownHandlers } from "./useSubmenuKeydownHandlers";
import { useSubmenuKeydownHandlers } from "./useSubmenuKeydownHandlers";

export type CompanyMenuItem = {
  id: string;
  link: string;
  title: string;
};

interface CompanyMenuProps extends SubmenuKeydownHandlers {
  handleClose: () => void;
  menuItems: CompanyMenuItem[];
}

const CompanyMenu: ForwardRefRenderFunction<
  HTMLDivElement,
  CompanyMenuProps
> = (
  { handleClose, menuItems, onEscapeKeyDown, onShiftTabKeyDown, onTabKeyDown },
  ref,
) => {
  const { submenuRefs, handleKeyDown } = useSubmenuKeydownHandlers({
    onEscapeKeyDown,
    onShiftTabKeyDown,
    onTabKeyDown,
  });
  return (
    <div
      css={css`
        background-color: ${colorWhite};
        border-radius: 16px;
        width: 264px;
        border: 1px solid ${colorSteelLighter};
        overflow: hidden;
        padding: 1rem 0;
      `}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `}
        role={"list"}
      >
        {menuItems.map((item, index) => (
          <MenuListItem
            compact={true}
            handleClose={handleClose}
            key={item.id}
            link={item.link}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onMouseLeave={() => submenuRefs.current[index]?.blur()}
            ref={(el) => (submenuRefs.current[index] = el)}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default forwardRef(CompanyMenu);
