import { colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import Image from "next/image";

import Icon from "../Icon";
import Text from "../Text";

interface MenuPreviewProps {
  className?: string;
  image: string;
  list: string[];
  title: string;
}

const MenuPreview = ({ className, image, list, title }: MenuPreviewProps) => {
  return (
    <div
      className={className}
      css={css`
        background-color: #ffffff;
        border-left: 1px solid ${colorSteelLighter};
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Image
        alt={""}
        css={css`
          margin-bottom: 2rem;
        `}
        height={"120"}
        src={image}
        width={"368"}
      />
      <div
        css={css`
          width: 300px;
        `}
      >
        <Text as={"h2"} color={"steelDarker"} size={"small"} weight={"medium"}>
          {title}
        </Text>
        <ul
          css={css`
            list-style: none;
            padding: 0;
          `}
        >
          {list.map((item) => (
            <li
              css={css`
                display: flex;
                gap: 1rem;
                margin-top: 1rem;
              `}
              key={item}
            >
              <Icon
                color={"blue"}
                css={css`
                  flex: 0 0 auto;
                `}
                size={"20px"}
                source={"blue-checkmark"}
                yPos={"5px"}
              />
              <Text
                as={"span"}
                color={"steelDarker"}
                size={"small"}
                weight={"regular"}
              >
                {item}
              </Text>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuPreview;
