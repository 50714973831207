import { colorSteelLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ForwardRefRenderFunction } from "react";
import { forwardRef, useState } from "react";

import MenuListItem from "./MenuListItem";
import MenuPreview from "./MenuPreview";
import type { SubmenuKeydownHandlers } from "./useSubmenuKeydownHandlers";
import { useSubmenuKeydownHandlers } from "./useSubmenuKeydownHandlers";

type InfoPanel = {
  arrowPosition: string;
  bulletedList: string[];
  image: string;
  title: string;
};

export type ProductMenuItem = {
  description?: string;
  id: string;
  infoPanel: InfoPanel;
  link: string;
  stickAtBottom?: boolean;
  title: string;
};

interface ProductMenuProps extends SubmenuKeydownHandlers {
  handleClose: () => void;
  menuItems: ProductMenuItem[];
}

const ProductMenu: ForwardRefRenderFunction<
  HTMLDivElement,
  ProductMenuProps
> = (
  { handleClose, menuItems, onEscapeKeyDown, onShiftTabKeyDown, onTabKeyDown },
  ref,
) => {
  const { submenuRefs, handleKeyDown } = useSubmenuKeydownHandlers({
    onEscapeKeyDown,
    onShiftTabKeyDown,
    onTabKeyDown,
  });
  const [activeMenuItemId, setActiveMenuItemId] = useState(menuItems[0].id);

  const activeMenuItem = menuItems.find((item) => item.id === activeMenuItemId);
  const handleMenuItemHover = (id: string) => {
    setActiveMenuItemId(id);
  };
  return (
    <div
      css={css`
        background-color: ${colorWhite};
        border-radius: 16px;
        width: 720px;
        border: 1px solid ${colorSteelLighter};
        overflow: hidden;
        height: 471px;
      `}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: stretch;
          height: 100%;
        `}
      >
        <div
          css={css`
            flex: 0 0 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
            padding: 1rem 0;
          `}
          role={"list"}
        >
          {menuItems.map((item, index) => (
            <MenuListItem
              css={css`
                ${item.stickAtBottom && "margin-top: auto;"}
              `}
              description={item.description}
              handleClose={handleClose}
              key={item.id}
              link={item.link}
              onHover={() => handleMenuItemHover(item.id)}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onMouseLeave={() => submenuRefs.current[index]?.blur()}
              ref={(el) => (submenuRefs.current[index] = el)}
              title={item.title}
              titleIcon={item.stickAtBottom ? "arrow-right" : undefined}
            />
          ))}
        </div>
        <div
          css={css`
            display: flex;
            align-items: stretch;
            position: relative;
          `}
        >
          {menuItems.map((item) => (
            <MenuPreview
              css={css`
                display: ${item.id === activeMenuItemId ? "flex" : "none"};
              `}
              image={item.infoPanel.image}
              key={item.id}
              list={item.infoPanel.bulletedList}
              title={item.infoPanel.title}
            />
          ))}
          <div
            css={css`
              border-right: 21px solid ${colorSteelLighter};
              border-top: 21px solid transparent;
              border-bottom: 21px solid transparent;
              position: absolute;
              left: -20px;
              top: ${activeMenuItem?.infoPanel.arrowPosition || "0"};
              transition: top 0.2s ease;
              width: 0;
              height: 0;

              &:after {
                border-right: 20px solid ${colorWhite};
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                content: "";
                position: absolute;
                top: -20px;
                left: 1px;
                width: 0;
                height: 0;
              }
            `}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ProductMenu);
