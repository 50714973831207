import { useRef } from "react";

export interface SubmenuKeydownHandlers {
  onEscapeKeyDown?: () => void;
  onShiftTabKeyDown?: () => void;
  onTabKeyDown?: () => void;
}

export const useSubmenuKeydownHandlers = ({
  onEscapeKeyDown,
  onShiftTabKeyDown,
  onTabKeyDown,
}: SubmenuKeydownHandlers) => {
  const submenuRefs = useRef<(HTMLAnchorElement | null)[]>([]);
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    index: number,
  ) => {
    const nextIndex = (index + 1) % submenuRefs.current.length;
    const prevIndex =
      (index - 1 + submenuRefs.current.length) % submenuRefs.current.length;
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        submenuRefs.current[nextIndex]?.focus();
        break;
      case "ArrowUp":
        event.preventDefault();
        submenuRefs.current[prevIndex]?.focus();
        break;
      case "Tab":
        event.preventDefault();
        if (event.shiftKey) {
          onShiftTabKeyDown?.();
        } else {
          onTabKeyDown?.();
        }
        break;
      case "Escape":
        event.preventDefault();
        onEscapeKeyDown?.();
        break;
    }
  };
  return { handleKeyDown, submenuRefs };
};
