import { colorSteelLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef, type ForwardRefRenderFunction } from "react";

import type { IconSource } from "../Icon/types";
import MenuListItem from "./MenuListItem";
import type { SubmenuKeydownHandlers } from "./useSubmenuKeydownHandlers";
import { useSubmenuKeydownHandlers } from "./useSubmenuKeydownHandlers";

export type ResourceMenuItem = {
  description?: string;
  icon?: string;
  id: string;
  link: string;
  title: string;
};

interface ResourceMenuProps extends SubmenuKeydownHandlers {
  handleClose: () => void;
  menuItems: ResourceMenuItem[];
}

const ResourceMenu: ForwardRefRenderFunction<
  HTMLDivElement,
  ResourceMenuProps
> = (
  { handleClose, menuItems, onEscapeKeyDown, onShiftTabKeyDown, onTabKeyDown },
  ref,
) => {
  const { submenuRefs, handleKeyDown } = useSubmenuKeydownHandlers({
    onEscapeKeyDown,
    onShiftTabKeyDown,
    onTabKeyDown,
  });
  const menuItemsWithIcons = menuItems.filter((item) => !!item.icon);
  const menuItemsWithoutIcons = menuItems.filter((item) => !item.icon);
  return (
    <div
      css={css`
        background-color: ${colorWhite};
        border-radius: 16px;
        width: 640px;
        border: 1px solid ${colorSteelLighter};
        overflow: hidden;
        padding: 1rem 0;
      `}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: stretch;
          height: 100%;
        `}
      >
        <div
          css={css`
            flex: 0 0 360px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          `}
          role={"list"}
        >
          {menuItemsWithIcons.map((item, index) => (
            <MenuListItem
              compact={true}
              description={item.description}
              handleClose={handleClose}
              icon={item.icon as IconSource}
              key={item.id}
              link={item.link}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onMouseLeave={() => submenuRefs.current[index]?.blur()}
              ref={(el) => (submenuRefs.current[index] = el)}
              title={item.title}
            />
          ))}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;
          `}
          role={"list"}
        >
          {menuItemsWithoutIcons?.map((item, index) => (
            <MenuListItem
              compact={true}
              handleClose={handleClose}
              key={item.id}
              link={item.link}
              onKeyDown={(event) =>
                handleKeyDown(event, menuItemsWithIcons.length + index)
              }
              onMouseLeave={() =>
                submenuRefs.current[menuItemsWithIcons.length + index]?.blur()
              }
              ref={(el) =>
                (submenuRefs.current[menuItemsWithIcons.length + index] = el)
              }
              title={item.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ResourceMenu);
