import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import {
  colorBlueMedium,
  colorWhite,
  layoutBannerHeight,
  sizeXxxlarge,
} from "@10xdev/design-tokens";
import { css, keyframes } from "@emotion/react";
import chroma from "chroma-js";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { createRef, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { useKey } from "react-use";

import Icon from "../Icon";
import IconButton from "../IconButton";
import ThematicBreak from "../ThematicBreak";
import MobileNavItems from "./MobileNavItems";
import NavSections from "./NavSections";

interface Props {
  data: Navigation_FullFragment;
  onClose: () => void;
  onNavigate: () => void;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    left: 100%;
  }
  100% {
    left: ${sizeXxxlarge};
  }
`;

const MobileNavMenu: FunctionComponent<Props> = ({
  data,
  onClose,
  onNavigate,
}) => {
  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState<
    NonNullable<Navigation_FullFragment["menu"]>[0] | null
  >(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const [targetMounted, setTargetMounted] = useState(false);

  const target = (
    <div
      css={css`
        left: 0;
        min-height: 100%;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
      `}
      ref={targetRef}
      role={"dialog"}
      tabIndex={-1}
    ></div>
  );

  const targetRefHasCurrent = Boolean(targetRef.current);

  useEffect(() => {
    setTargetMounted(Boolean(targetRef.current));
  }, [targetRefHasCurrent]);

  const closeButtonRef = createRef<HTMLButtonElement>();
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  });

  useKey("Escape", onClose);

  const handleBack = () => setSelectedItem(null);
  const handleItemClick = (
    item: NonNullable<Navigation_FullFragment["menu"]>[0],
  ) => {
    if (item.url) {
      router.push(item.url);
    } else {
      setSelectedItem(item);
    }
  };

  const portal = targetMounted
    ? createPortal(
        <FocusLock returnFocus={true}>
          <RemoveScroll>
            <div
              css={css`
                animation: ${fadeIn} 0.25s;
                background-color: ${chroma(colorBlueMedium).alpha(0.9).hex()};
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
              `}
              tabIndex={-1}
            />

            <div
              css={css`
                animation: ${slideIn} 0.25s;
                background-color: ${colorWhite};
                bottom: 0;
                left: ${sizeXxxlarge};
                overflow-x: hidden;
                overflow-y: auto;
                position: absolute;
                right: 0;
                top: 0;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
              `}
            >
              <div
                css={css`
                  align-items: center;
                  display: flex;
                  height: ${layoutBannerHeight};
                  justify-content: space-between;
                `}
              >
                <div>
                  {selectedItem ? (
                    <IconButton onClick={handleBack}>
                      <Icon color={"blue"} size={"18px"} source={"nav-left"} />
                    </IconButton>
                  ) : null}
                </div>
                <div>
                  {selectedItem ? null : (
                    <IconButton onClick={() => onClose()} ref={closeButtonRef}>
                      <Icon color={"blue"} size={"18px"} source={"close"} />
                    </IconButton>
                  )}
                </div>
              </div>

              <ThematicBreak />

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                {!selectedItem && data.menu && (
                  <MobileNavItems items={data.menu} onClick={handleItemClick} />
                )}

                {selectedItem?.items ? (
                  <NavSections
                    direction={"column"}
                    items={selectedItem.items}
                    onNavigate={onNavigate}
                  />
                ) : null}
              </div>
            </div>
          </RemoveScroll>
        </FocusLock>,

        targetRef.current!,
      )
    : null;

  return (
    <>
      {target}
      {portal}
    </>
  );
};

export default MobileNavMenu;
