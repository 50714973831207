import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import type { FunctionComponent } from "react";

import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

interface Props {
  /** Data for the site nav */
  data: Navigation_FullFragment;

  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: "light" | "dark";
}

const Navigation: FunctionComponent<Props> = ({ data, mode = "dark" }) => {
  return (
    <>
      <DesktopNav data={data} mode={mode} />
      <MobileNav data={data} mode={mode} />
    </>
  );
};

export default Navigation;
