import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";

import Icon from "../Icon";
import IconButton from "../IconButton";
import MobileNavMenu from "./MobileNavMenu";

interface Props {
  data: Navigation_FullFragment;

  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: "light" | "dark";
}

const MobileNav: FunctionComponent<Props> = ({ data, mode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpenButtonClick = () => setIsOpen(true);

  return (
    <nav
      css={css`
        display: none;
        @media (max-width: 1070px) {
          display: initial;
        }
        margin-left: auto;
      `}
    >
      <IconButton onClick={handleOpenButtonClick}>
        <Icon
          color={mode === "dark" ? "white" : "base"}
          size={"28px"}
          source={"hamburger"}
          title={"Open Menu"}
          yPos={"4px"}
        />
      </IconButton>

      {isOpen ? (
        <MobileNavMenu
          data={data}
          onClose={handleClose}
          onNavigate={handleClose}
        />
      ) : null}
    </nav>
  );
};

export default MobileNav;
